import { render, staticRenderFns } from "./WebsiteButton.vue?vue&type=template&id=83676cea&"
import script from "./WebsiteButton.ts?vue&type=script&lang=ts&"
export * from "./WebsiteButton.ts?vue&type=script&lang=ts&"
import style0 from "./WebsiteButton.sass?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports